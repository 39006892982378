import React from "react";
import Body from "../Components/Body/Body";

function Home() {
    return (
        <div>
            <Body />
        </div>
    );
};

export default Home;
import React from "react";

function Logout() {
    return (
        <div>
            <h1>You've been successfully logged out.</h1>
        </div>
    );
};

export default Logout;